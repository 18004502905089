<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <Breadcrumbs :title="labels" :items="items"/>

      <Snackbar ref="snackbar"/>
      <Confirmation @action-delete="deleteActivity()"/>

      <v-row justify="space-between" class="mt-2" v-if="$store.state.process.run == false || searching != ''">
        <v-col cols="4" v-if="$store.state.process.run == false || searching != ''">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Cari Activity . . ."
              v-model="searching"
              autocomplete="off"
              v-on:keyup.enter="fetch()"
              clearable
              color="#F05326"
              @click:clear="searching = '';fetch()"
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div class="d-flex float-right" v-if="$store.state.process.run == false && data.total > 0">
            <v-btn 
              depressed
              dense
              @click="filter"
              elevation="0"
              color="#F5F5F5" 
              class="black--text text-capitalize mr-4">
              Filter by
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn>
            <v-btn
              color="#F05326"
              depressed
              dense
              @click="$router.push(`/activity/create/${$route.params.id_topic}`)"
              class="white--text text-capitalize">
              Add New Activity
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="text-center mt-7" v-if="$store.state.process.run == false && data.total == 0">
        <div class="mt-5">
          <v-img :src="require('@/assets/img/empty.png')" width="200" class="mx-auto"></v-img>
        </div>
        <div class="mt-3">Please add new activity</div>
        <div class="mt-2">by click the button below</div>
        <v-btn
          color="#F05326"
          depressed
          dense
          @click="$router.push(`/activity/create/${$route.params.id_topic}`)"
          class="mt-3 white--text text-capitalize">
          Add New Activity
        </v-btn>
      </div>

      <div v-if="$store.state.process.run" class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table">
        </v-skeleton-loader>
      </div>
      
      <div  v-if="$store.state.process.run == false && data.total > 0" class="mt-3 mb-8">
        <v-data-table
          hide-default-footer
          fixed-header
          :loading="$store.state.process.run"
          :headers="headers"
          :items="data.list"
          :items-per-page.sync="data.limit"
          :page.sync="data.page"
          item-key="id"
            :server-items-length="data.total"
          :footer-props="{
            itemsPerPageText : 'Data per halaman',
            'items-per-page-options': [5,10, 20, 50, 100, 200]
          }">
          <template slot="footer" slot-scope="{ props }">
            <Pagination 
              @reload="fetch(page)"
              @changeLimit="limit = $event; fetch()" 
              @changePage="page = $event; fetch($event)" 
              :model="props"/>
          </template>
          <template v-slot:[`item.activity_type`]="{ item }">
            {{ renderActivityType(item.activity_type) }}
          </template>
          <template v-slot:[`item.pdf`]="{ item }">
            <a :href="item.pdf" v-if="item.pdf != ''" target="_blank" style="color: #F05326">
              <v-icon>mdi-file-pdf-box</v-icon>
              View Document
            </a>
            <div v-else>
              <v-icon>mdi-file-pdf-box</v-icon>
              Document tidak tersedia
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs ,on }">
                <v-btn
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="createQuestion(item)"
                  color="#F05326"
                  class="mr-2"
                  icon>
                  <v-icon>mdi-clipboard-outline</v-icon>
                </v-btn>
              </template>
              <span>Add Questions</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs ,on }">
                <v-btn
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  color="blue"
                  :to="`/activity/update/${$route.params.id_topic}/${item.id}`"
                  icon>
                  <!-- @click="EditActivity(item)" -->
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
              </template>
              <span>Edit Activity</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs ,on }">
                <v-btn
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  @click="confirmation(item.id)"
                  class="mr-2"
                  icon>
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete Activity</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
      
    </div>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import GridTableGreeting from '@/components/course/activity/GridTableGreeting.vue'
import Pagination from '@/components/Pagination.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import Confirmation from '@/components/Confirmation.vue'

export default {
  components: { GridTableGreeting, Pagination, Breadcrumbs, Snackbar, Confirmation },
  data() {
    return {
      items: [
        {
          text: 'List Topic',
          disabled: false,
          href: `/topic`
        },
        {
          text: "Activity",
          disabled: true  ,
          href: `/activity/${this.$route.params.id_topic}`,
        },
      ],
      singleSelect: false,
      selected: [],
      activity_type: [],
      headers: [
        { text: 'Activity Type', value: 'title', sortable: false },
        { text: 'Total Question', value: 'total_questions', sortable: false },
        { text: 'Document Activity', value: 'pdf', sortable: false },
        { text: '', value: 'action',align: "end", sortable: false }
      ],
      searching: "",
      labels: "",
      id_activity: "",
      pagination: {},
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      data: {},
      process: {
        run: false
      },
      paid:false,
      pin:false,
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
  },
  computed:{},
  created(){
    this.fetch()
    this.fetchDetailTopic()
    // this.fetchActivityType()
  },
  mounted(){},
  methods:{
    filter(){
      this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    fetchAll(){
      this.fetch()
      this.fetchDetailTopic()
      // this.fetchActivityType()
    },
    async fetch(paging){
      this.$store.state.process.run = true;
      await get(`api/v1/activity/list/${this.$route.params.id_topic}`, {
        params: {
          limit: this.limit,
          page: paging,
          search_query: this.searching
        }
      }).then((response) => {
        this.$store.state.process.run = false;
        let res = response.data
        this.data = res.data;
      });
    },
    fetchDetailTopic(){
      get(`api/v1/topic/detail/${this.$route.params.id_topic}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.items[1].text = res.data.topic_name
          this.labels = res.data.topic_name
          this.paid=res.data.is_paid;
          this.pin=res.data.is_pinned;
        }
      })
    },
    fetchActivityType(){
      get(`api/v1/activity/type/list`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.activity_type = res.data.list
        }
      })
    },
    renderActivityType(item) {
      let data = "";
      this.activity_type.forEach((element) => {
        if (item == element.name) {
          data = element.label;
        }
      });
      return data;
    },
    EditActivity(item){
      this.$router.push(`/activity/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${item.id}`)
    },
    confirmation(id){
      this.$store.state.dialog.confirmDelete = true
      this.id_activity = id
    },
    async deleteActivity(item){
      this.$store.state.process.run = false
      await destroy(`api/v1/activity/delete/${this.$route.params.id_topic}/${this.id_activity}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.fetchAll()
          this.$refs.snackbar.open("#4CAF50", `Activity Deleted Succesfully`);
        }else{
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.fetchAll()
          this.$refs.snackbar.open("error", `Activity Deleted Failed`);
        }
      })
    },
    createQuestion(item){
      this.$store.state.pagination.limit = 10
      this.$store.state.pagination.page = 1
      this.$router.push(`/questions/list/${this.$route.params.id_topic}/${item.id}`)
    },
  }
}
</script>